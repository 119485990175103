var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',{staticClass:"background-2"},[_c('v-card-text',{staticStyle:{"height":"calc(100% - 52px)"},attrs:{"id":"endpoints-edit__card-text"}},[_c('Fields',{attrs:{"schema":{
        identifier: {
          label: _vm.$t('endp_edit.schema.identifier.label'),
          type: 'string',
          description: _vm.$t('endp_edit.schema.identifier.description'),
          fieldOptions: {
            color: _vm.accentColor,
            placeholder: _vm.$t('endp_edit.schema.identifier.placeholder'),
            validatePattern: '^[a-z0-9\-_]+$',
            validateCaseInsensitive: false,
            validateCustomError: _vm.$t('endp_edit.schema.identifier.error')
          },
          newDesign: true
        },
        name: {
          type: 'string',
          label: _vm.$t('endp_edit.schema.name.label'),
          description: _vm.$t('endp_edit.schema.name.description'),
          newDesign: true
        },
        type: {
          type: 'select',
          label: _vm.$t('endp_edit.schema.type.label'),
          fieldOptions: {
            options: _vm.endpointTypes
          },
          newDesign: true
        },
        requireToken: {
          type: 'boolean',
          label: _vm.$t('endp_edit.schema.requireToken.label'),
          optional: true,
          newDesign: true
        }
      },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.endpoint),callback:function ($$v) {_vm.endpoint=$$v},expression:"endpoint"}}),_c('v-slide-y-transition',[(['view', 'list'].includes(_vm.endpoint.type))?_c('Fields',{key:"view",attrs:{"schema":{
          collectionId: {
            label: _vm.$t('endp_edit.schema.collectionId.label'),
            type: 'collectionSelect',
            newDesign: true
          },
          filterId: {
            type: 'filterSelect',
            label: _vm.$t('endp_edit.schema.filterId.label'),
            fieldOptions: {
              filterByKey: 'collectionId',
              filterByValue: _vm.endpoint.collectionId
            },
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.endpoint),callback:function ($$v) {_vm.endpoint=$$v},expression:"endpoint"}}):_vm._e(),(_vm.endpoint.type === 'indicator')?_c('Fields',{key:"view",attrs:{"schema":{
          indicatorId: {
            label: _vm.$t('endp_edit.schema.indicatorId.label'),
            type: 'indicatorSelect'
          },
          filterId: {
            type: 'filterSelect',
            label: _vm.$t('endp_edit.schema.filterId.label')
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.endpoint),callback:function ($$v) {_vm.endpoint=$$v},expression:"endpoint"}}):_vm._e(),(_vm.endpoint.type === 'form')?_c('Fields',{key:"form",attrs:{"schema":{
          formId: {
            label: _vm.$t('endp_edit.schema.formId.label'),
            type: 'formSelect'
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.endpoint),callback:function ($$v) {_vm.endpoint=$$v},expression:"endpoint"}}):_vm._e()],1),_c('v-slide-y-transition',[(_vm.endpoint.requireToken)?_c('div',[_c('h2',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('endp_edit.schema.identifier.tokens'))+" ")]),_c('v-list',[_vm._l((_vm.endpoint.tokens),function(token){return _c('v-list-item',{key:token,attrs:{"href":"#"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(token)}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeToken(token)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)}),_c('v-list-item',{on:{"click":_vm.addToken}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("add")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('endp_edit.schema.identifier.genToken')))])],1)],1)],2)],1):_vm._e()])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":_vm.$apollo.loading || _vm.saving,"title":_vm.$t('endp_edit.schema.identifier.deleteEnd')},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","icon":"","title":_vm.$t('endp_edit.schema.identifier.undo'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{attrs:{"color":"primary","dark":"","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v(_vm._s(_vm.$t('endp_edit.schema.identifier.cancelBtn')))]),_c('v-btn',{attrs:{"color":"primary","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('endp_edit.schema.identifier.saveBtn')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }